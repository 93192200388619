import React, { useRef, useState, useEffect } from "react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from "../styles/CaseStudyGrid.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Chevron } from "./icon/chevron";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import Button from "./button";
import WistiaVideo from "./wistia-video";
import { pauseWistiaVideos } from "../helper/wistiaUtils";
import { generateRandomId } from "../helper/utils";

export default function CaseStudyGrid({
    resource_grid
}: {
    resource_grid: ResourceGridProp;
    taxonomies?: [{ taxonomy_uid: string; term_uid: string }];
}) {
    const prevRef = useRef<HTMLButtonElement | null>(null);
    const nextRef = useRef<HTMLButtonElement | null>(null);
    const swiperRef = useRef<SwiperType | null>(null);
    const paginationRef = useRef<HTMLDivElement | null>(null);
    const slidesPerView = 1;
    const caseStudies = resource_grid.case_studies || [];
    const [paginationId] = useState(generateRandomId());

    const [hasMounted, setHasMounted] = useState(false);
    const [navigationReady, setNavigationReady] = useState(false);

    // Track whether the component has mounted (to avoid React Router refresh timing bugs)
    useEffect(() => {
        setHasMounted(true);
    }, []);

    // Wait until both refs and slides are available
    useEffect(() => {
        if (
            hasMounted &&
            prevRef.current &&
            nextRef.current &&
            caseStudies.length > 0
        ) {
            setNavigationReady(true);
        }
    }, [hasMounted, caseStudies.length]);

    // Once everything is ready, update Swiper navigation manually
    useEffect(() => {
        if (
            navigationReady &&
            swiperRef.current &&
            prevRef.current &&
            nextRef.current &&
            swiperRef.current.params.navigation
        ) {
            swiperRef.current.params.navigation.prevEl = prevRef.current;
            swiperRef.current.params.navigation.nextEl = nextRef.current;

            swiperRef.current.navigation.destroy();
            swiperRef.current.navigation.init();
            swiperRef.current.navigation.update();
        }
    }, [navigationReady]);

    const defaultImage = caseStudies[0]?.image ?? null;

    const handleSlideChange = () => {
        pauseWistiaVideos();
    };

    return (
        <div className={styles.container}>
            <div className={styles.blogGridHeading}>
                <h3 className={styles.blogGridEyebrow}>
                    {resource_grid.eyebrow.toUpperCase()}
                </h3>
                <h2 className={styles.blogGridTitle}>{resource_grid.title}</h2>
            </div>

            {/* Only render Swiper once everything is ready */}
            {navigationReady && (
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
                    slidesPerView={slidesPerView}
                    spaceBetween={40}
                    speed={800}
                    className={`${styles.carousel} case-study-grid-swiper`}
                    loop={true}
                    navigation={{
                        prevEl: prevRef.current,
                        nextEl: nextRef.current,
                    }}
                    pagination={{
                        el: `#${paginationId}`,
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    onSlideChange={handleSlideChange}
                >
                    {caseStudies.map((slide, index) => {
                        const imageToUse = slide?.image || defaultImage;
                        return (
                            <SwiperSlide key={index}>
                                <div className={styles.contentCarousel}>
                                    <div className={styles.contentText}>
                                        <div>
                                        <h3
                                            className={styles.titleCarousel}
                                            dangerouslySetInnerHTML={{
                                                __html: slide?.title,
                                            }}
                                        ></h3>
                                        {slide?.description && (
                                            <div
                                                className={styles.descriptionCarousel}
                                                dangerouslySetInnerHTML={{
                                                    __html: slide?.description,
                                                }}
                                            ></div>
                                            )}
                                        </div>
                                        <Button
                                            text={resource_grid.cta.title}
                                            showIcon
                                            propWidth="250px"
                                            propBackgroundColor="var(--primary-500)"
                                            propHoverBackgroundColor="var(--white)"
                                            propTextColor="var(--white)"
                                            propHoverText="var(--primary-500)"
                                            link={resource_grid.cta.url}
                                        />
                                    </div>
                                    {imageToUse && (
                                        <div className={styles.contentImg}>
                                            <img
                                                src={`${imageToUse.url}?width=584&auto=webp&fit=crop,smart`}
                                                alt={imageToUse.description}
                                            />
                                        </div>
                                    )}
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            )}

            {caseStudies.length > 1 && (
                <div className={styles.navigationWrapper}>
                    <button ref={prevRef} className={styles.prevButton}>
                        <Chevron
                            direction="left"
                            color="var(--chevron-color, var(--charcoal-500))"
                            size={20}
                        />
                    </button>
                    <div
                        ref={paginationRef}
                        className={styles.pagination}
                        id={paginationId}
                    ></div>
                    <button ref={nextRef} className={styles.nextButton}>
                        <Chevron
                            direction="right"
                            color="var(--chevron-color, var(--charcoal-500))"
                            size={20}
                        />
                    </button>
                </div>
            )}
        </div>
    );
}
